import React, { useState, useEffect } from "react";
import "./home.css";
import Carousel from "react-bootstrap/Carousel";
import { Link } from "react-router-dom";
const Home = () => {

  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  const [carouselData, setCarouselData] = useState({ carouselItems: [] });


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://s3.amazonaws.com/assets.enginuo.com/carousel_contents/services-carousel.json');
        const data = await response.json();
        setCarouselData(data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  return (

    <div style={{ marginTop: "6rem" }} className="homepage-carousel">
      <Carousel data-bs-theme="dark">
        {carouselData.carouselItems.map((item, index) => (
          <Carousel.Item key={index} interval={2000}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}>
            <div
              className={`carousel-bg ${isHovered ? 'zoom-in' : ''}`}
              style={{ backgroundImage: `url(${item.imageUrl})` }}
            />
            <Carousel.Caption >
              <p className="carousel-captions-tittle">{item.title}</p>
              <h5 className="carousel-captions-description">{item.description}</h5>
              <Link to={`/${item.path}`}>
                <button className="read-more-button">
                  Learn More
                </button>
              </Link>
            </Carousel.Caption>
          </Carousel.Item>
        ))}
      </Carousel>

    </div>
  );
};

export default Home;