import React, { useState } from 'react';
import { Container, Nav, Navbar, NavDropdown } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import logo from '../../../Assets/enginuo.png';
import aiLogo from '../../../Assets/download.svg';
import './header.css';

function Header() {
  const [isAIDropdownOpen, setAIDropdownOpen] = useState(false);
  const [isProductsDropdownOpen, setProductsDropdownOpen] = useState(false);
  const [isServicesDropdownOpen, setServicesDropdownOpen] = useState(false);
  const navigate = useNavigate();

  const handleAIDropdownOpen = () => {
    setAIDropdownOpen(true);
  };

  const handleAIDropdownClose = () => {
    setAIDropdownOpen(false);
  };

  const handleProductsDropdownOpen = () => {
    setProductsDropdownOpen(true);
  };

  const handleProductsDropdownClose = () => {
    setProductsDropdownOpen(false);
  };

  const handleServicesDropdownOpen = () => {
    setServicesDropdownOpen(true);
  };

  const handleServicesDropdownClose = () => {
    setServicesDropdownOpen(false);
  };

  return (
    <Navbar expand="lg" className="header-navbar mb-5">
      <Container fluid>
        <Navbar.Brand as={Link} to='/'>
          <img
            src={logo}
            alt="Logo"
            height="30"
            className="header-logo d-inline-block align-top"
          />
        </Navbar.Brand>

        <Navbar.Toggle aria-controls="navbarScroll" style={{ backgroundColor: "white" }} />
        <Navbar.Collapse id="navbarScroll">
          <Nav
            className="ms-auto my-2 my-lg-0 justify-content-center"
            style={{ maxHeight: '300px' }}
            navbarScroll
          >

              <NavDropdown title="AI Solutions" className="text-light" id="navbarScrollingDropdown"
                show={isAIDropdownOpen} onMouseOver={handleAIDropdownOpen} onMouseLeave={handleAIDropdownClose} >
                <div style={{ width: '500px', display: 'flex', }}>
                  <div>
                    {/* <NavDropdown.Item as={Link} to="/aisolution" className='navbar-drop-down'>AI Solutions</NavDropdown.Item> */}
                    {/* <NavDropdown.Divider className='navbar-divider' /> */}
                    <NavDropdown.Item as={Link} to="/genai" className='navbar-drop-down'>GenAI Developer Platform</NavDropdown.Item>
                    <NavDropdown.Divider className='navbar-divider' />
                    <NavDropdown.Item as={Link} to="/foundational" className='navbar-drop-down'>Foundational Models </NavDropdown.Item>
                    <NavDropdown.Divider className='navbar-divider' />
                    <NavDropdown.Item as={Link} to="/taskspecific" className='navbar-drop-down'>Task Specific Models</NavDropdown.Item>
                    <NavDropdown.Divider className='navbar-divider' />
                    <NavDropdown.Item as={Link} to="/opensource" className='navbar-drop-down'>Opensource Models</NavDropdown.Item>
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}><img src="https://static.vecteezy.com/system/resources/previews/023/841/800/original/adorable-blue-bots-small-cute-robots-generated-by-ai-free-png.png" alt="podModel" style={{ height: '200px', width: "200px" }} /></div>
                </div>
              </NavDropdown>

            <NavDropdown title="Products & Platforms" className="text-light" id="navbarScrollingDropdown"
              show={isProductsDropdownOpen} onMouseOver={handleProductsDropdownOpen} onMouseLeave={handleProductsDropdownClose}>
              <div style={{ width: '500px', display: 'flex', }}>
                <div>
                  <NavDropdown.Item as={Link} to="/genai" className='navbar-drop-down'>GenAI Developer Platform</NavDropdown.Item>
                  <NavDropdown.Divider className='navbar-divider' />
                  <NavDropdown.Item as={Link} to="/products" className='navbar-drop-down'>Cloud Security Monitoring</NavDropdown.Item>
                  <NavDropdown.Divider className='navbar-divider' />
                </div>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', }}><img src={aiLogo} alt="podModel" style={{ height: '200px', width: "300px", }} /></div>

              </div>

            </NavDropdown>






            <NavDropdown title="Services" className="text-light" id="navbarScrollingDropdown"
              show={isServicesDropdownOpen} onMouseOver={handleServicesDropdownOpen} onMouseLeave={handleServicesDropdownClose}>
              <div style={{ display: 'flex', }}>
                <div>
                  <NavDropdown.Item as={Link} to="/cto" className='navbar-drop-down'>CTO as a Service</NavDropdown.Item>
                  <NavDropdown.Divider className='navbar-divider' />
                  <NavDropdown.Item as={Link} to="/softwaredevelopment" className='navbar-drop-down'>Custom Software Development</NavDropdown.Item>
                  <NavDropdown.Divider className='navbar-divider' />
                  <NavDropdown.Item as={Link} to="/dataanalytics" className='navbar-drop-down'> Data & Analytics</NavDropdown.Item>
                  <NavDropdown.Divider className='navbar-divider' />
                  <NavDropdown.Item as={Link} to="/aws" className='navbar-drop-down'>AWS Managed Cloud Service</NavDropdown.Item>
                  <NavDropdown.Divider className='navbar-divider' />
                  <NavDropdown.Item as={Link} to="/devops" className='navbar-drop-down'>Managed DevOps</NavDropdown.Item>
                </div>
                {/* <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}><img src="https://static.vecteezy.com/system/resources/previews/023/841/800/original/adorable-blue-bots-small-cute-robots-generated-by-ai-free-png.png" alt="podModel" style={{ height: '200px', width: "200px" }} /></div> */}
              </div>

            </NavDropdown>

            <Nav.Link as={Link} to="/careers">Careers</Nav.Link>
            <Nav.Link className='contact-us' as={Link} to="/contactus">Contact</Nav.Link>
            <Nav.Link as={Link} to="/about">About<span className='px-1'>US</span></Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Header;