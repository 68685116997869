import React, { useEffect } from "react";
import "./products.css";
import multicloud from "../../Assets/multi cloud support.svg";
import driftdetection from "../../Assets/drift detection.svg";
import robustalerting from "../../Assets/robust.svg";
import advancedcspm from "../../Assets/advanced cspm.svg";
import continuousmonitoring from "../../Assets/continous monitoring.svg";
import userfriendlydashboard from "../../Assets/user friendly.svg";


const ServiceItem = ({ icon, title, content }) => (
  <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
    <div className="product-item position-relative h-100">
      <div className="product-text rounded p-5">
        <div className="btn-square bg-light rounded-circle mx-auto mb-4" style={{ width: '64px', height: '64px' }}>
          <img className="img-fluid" src={icon} alt="Icon" />
        </div>
        <h5 className="mb-3">{title}</h5>
        <p className="mb-0">{content}</p>
      </div>
      <div className="product-btn rounded-0 rounded-bottom">
        <a className="text-primary fw-medium" href="">Read More<i className="bi bi-chevron-double-right ms-2"></i></a>
      </div>
    </div>
  </div>
);

function Products() {
  // Scroll to the top of the component when the component mounts
  useEffect(() => {
    window.scrollTo(0, 0);

  }, []);


  const servicesData = [
    {
      title: "Multi Cloud Support",
      content:
        "Amazon Web Service, Azure, and GCP Integration. Unified Security Management across all platforms",
      icon: multicloud,
    },
    {
      title: "Drift Detection",
      content:
        "Detect & rectify configuration changes to avoid potential security vulnerabilities while building a website.",
      icon: driftdetection,
    },
    {
      title: "Robust Alerting & Reporting",
      content:
        "Instant Alerts on Security Incidents. Comprehensive Security Reports for Informed Decision Making.",
      icon: robustalerting,
    },
    {
      title: "Advanced CSPM",
      content:
        "Advanced CSPM: Ensure Security Compliance. Identify and mitigate risks swiftly. Strengthen your defenses effortlessly.",
      icon: advancedcspm,
    },
    {
      title: "Continuous Monitoring",
      content:
        " Ensure 24/7 Monitoring of your Cloud  Environment. Detect anomalies and potential threats in real time vigilantly and proactively.",
      icon: continuousmonitoring,
    },
    {
      title: "User Friendly Dashboard",
      content:
        "Design an intuitive interface for easy navigation and ensure quick access to key security insights, enhancing the overall user experience.",
      icon: userfriendlydashboard,
    },
  ];

  
  const Card = ({ title, content, icon }) => {
    return (
      <div className="cardProduct">
        <div className="content">
          <img src={icon} alt="multicloud" className="iconProducts" />
          <h3 style={{ paddingBottom: '5%' }}>{title}</h3>
          <p>{content}</p>
          {/* <a href="/">Read More</a> */}
        </div>
      </div>
    );
  };

  return (
    <div className="container-aboutus">
      {/* <Row> */}
      <div className="content-container">
        <div className="text-container">
          <h2 className="py-5 fontChange" style={{ color: "#FFFFFF" }}>
            Build Cloud Agnostic Products and Platforms for grateful outcomes
          </h2>
          <p
            style={{
              fontSize: "18px",
              color: "white",
              fontWeight: "300",
              maxWidth: "500px",
            }}
          >
            <i>
              {" "}
              Accelerate Products and Platform delivery at which you create
              grateful business value. Our Engineering Team consistently
              validates and optimizes products, provides end to end-life
              engineering services,
            </i>
          </p>
        </div>
        <div className="image-container">
          <img
            src={require("../../Assets/image1.png")}
            className="image"
            alt=""
          />
        </div>
      </div>
      <div className="container-about">
        {/* <div className="cloudContainer" style={{ marginLeft: "4rem" }}>
          <h1 className="hr-lines-product">
            Cloud Security<span id="span"> Monitoring</span>{" "}
          </h1>
          <h5 style={{ marginLeft: "4rem" }}>
            <i>Secure your cloud with Enginuo's comprehensive monitoring</i>
          </h5>
          <p style={{ marginLeft: "4rem", fontSize: "18px" }}>
            Enginuo empowers businesses by enhancing the security of AWS, Azure,
            and GCP, the three major cloud platforms, through cutting edge Cloud
            Security Posture Management (CSPM), drift detection, and continuous
            monitoring
          </p>
        </div> */}

        <div className="cloudContainer " style={{  }}>
          <h1 className="hr-lines-product mt-4">
            Cloud Security<span id="span"> Monitoring</span>{" "}
          </h1>
          <h5 style={{  }} className="sub-heading">
            <i>Secure your cloud with Enginuo's comprehensive monitoring</i>
          </h5>
          <p className="cloudContainer-content" style={{  fontSize: "18px",}} >
            Enginuo empowers businesses by enhancing the security of AWS, Azure,
            and GCP, the three major cloud platforms, through cutting edge Cloud
            Security Posture Management (CSPM), drift detection, and continuous
            monitoring
          </p>
        </div>

        <div className=" py-5">
      <div className="">
        
        <div className="row g-4 px-4 py-5 mx-5 my-5">
          {servicesData.map((service, index) => (
            <ServiceItem key={index} {...service} />
          ))}
        </div>
      </div>
    </div>
      </div>
    </div>
  );
}

export default Products;
