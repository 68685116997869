import React, { useEffect } from 'react'
import './foundational.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faLanguage, faEye, faBook, faPuzzlePiece, faChartLine, faCog, faArrowsAltV, faBullseye, faBolt, faCogs, faShieldAlt, faMoneyBillAlt } from '@fortawesome/free-solid-svg-icons';



function FoundationalModel() {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="container-xxl  ">
        <div className="container-fluid py-5 mt-5">

          {/* =================Features and Offerings================== */}
          <div className="row g-5 py-5 ">
            <div className="col-lg-6 wow fadeInUp " data-wow-delay="0.1s">
              <div className="border-start border-5 border-primary ps-4 mb-5">
                <h1 className="display-6 ">Foundational Models</h1>
              </div>
              <p className="mb-5">At Enginuo, we pride ourselves on offering cutting-edge AI solution services, and our Foundational Models stand as a cornerstone in driving innovation and transformation for businesses across diverse industries</p >
              <div className="sectioner-header text-center">
                <h3 >Features and Offerings</h3>
              </div>
              <div className="row gy-5 gx-4 mt-2">
                <div className="col-sm-6 wow fadeIn" data-wow-delay="0.1s">
                  <div className="d-flex align-items-center mb-3">
                    <i className="fa fa-check fa-2x text-primary flex-shrink-0 "></i>
                    <p className="mb-0"><FontAwesomeIcon icon={faPuzzlePiece} size='2x' className="foundation-model-icon me-2" /><b>Versatility and Adaptability</b> </p >
                  </div>
                  <span>Our Foundational Models are designed to be adaptable across various industries and applications. They serve as a versatile starting point for developing AI solutions tailored to your specific needs</span>
                </div>
                <div className="col-sm-6 wow fadeIn" data-wow-delay="0.2s">
                  <div className="d-flex align-items-center mb-3">
                    <i className="fa fa-check fa-2x text-primary flex-shrink-0 me-3"></i>
                    <p className="mb-0"><FontAwesomeIcon icon={faChartLine} size='2x' className="foundation-model-icon me-2" /><b>Proven Performance</b></p >
                  </div>
                  <span>These models undergo rigorous testing and validation processes, ensuring high performance, accuracy, and reliability in diverse scenarios</span>
                </div>
                <div className="col-sm-6 wow fadeIn" data-wow-delay="0.3s">
                  <div className="d-flex align-items-center mb-3">
                    <p className="mb-0"><FontAwesomeIcon icon={faCog} size='2x' className="foundation-model-icon me-2" /> <b>Customization Capabilities</b></p >
                  </div>
                  <span>While being robust foundations, these models can be fine-tuned and customized to suit your unique business requirements and use cases</span>
                </div>
                <div className="col-sm-6 wow fadeIn" data-wow-delay="0.4s">
                  <div className="d-flex align-items-center mb-3">
                    <i className="fa fa-check fa-2x text-primary flex-shrink-0 me-3"></i>
                    <p className="mb-0"><FontAwesomeIcon icon={faArrowsAltV} size='2x' className="foundation-model-icon me-2" /><b>Scalability and Efficiency</b> </p >
                  </div>
                  <span>Leveraging these models provides scalability and efficiency in AI development, saving time and resources during the solution creation process.</span>
                </div>
              </div>
            </div>
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.5s">
              <div className="position-relative overflow-hidden ps-5 pt-5 h-100" style={{ minHeight: '400px' }}>
                <img className="position-absolute w-100 h-100" src="https://www.analyticsinsight.net/wp-content/uploads/2021/09/Python-IDEs-and-Code-Editors.jpg" alt="" style={{ objectFit: 'cover' }} />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* =========================Industry Applications========================= */}

      <div className="container-fluid py-5 wow fadeInUp industry-application" data-wow-delay="0.1s">
        <div className="container py-5">
          <div className="section-title text-center position-relative pb-3 mb-5 mx-auto" style={{ maxWidth: '600px' }}>
            <h1 className="fw-bold  ">Industry Applications</h1>
            <h5 className="mb-0">Our Foundational Models cater to a broad spectrum of industries, enabling solutions in areas such as</h5>
          </div>
          <div className="row g-5">
            <div className="col-lg-4">
              <div className="row g-5">
                <div className="col-12 wow zoomIn" data-wow-delay="0.2s">
                  <div className="rounded d-flex align-items-center justify-content-center  mb-3 industry-icon-card" >
                    <FontAwesomeIcon icon={faLanguage} style={{ color: '#00C389' }} size='3x' />
                  </div>
                  <h4>Natural Language Processing </h4>
                  <p className="mb-0">Text analysis, sentiment analysis, language translation</p>
                </div>
                <div className="col-12 wow zoomIn" data-wow-delay="0.6s">
                  <div className=" rounded d-flex align-items-center justify-content-center mb-3 industry-icon-card" >
                    <FontAwesomeIcon icon={faEye} style={{ color: '00C389' }} size='3x' />
                  </div>
                  <h4>Computer Vision</h4>
                  <p className="mb-0">Object detection, image segmentation, facial recognition</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 wow zoomIn" data-wow-delay="0.9s" style={{ minHeight: '350px' }}>
              <div className="position-relative h-100">
                <img className="position-absolute w-100 h-100 rounded wow zoomIn" data-wow-delay="0.1s" src="https://media2.giphy.com/media/v1.Y2lkPTc5MGI3NjExaWM3bzE1aG5lZ2l2NHJrcDFlOWg0cHptbTJxamFsODA5cTc5amFvOSZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/n6mEMqAuYOQ8l8qcEE/giphy.gif" style={{ objectFit: 'cover' }} alt="Feature" />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="row g-5">
                <div className="col-12 wow zoomIn" data-wow-delay="0.4s">
                  <div className="rounded d-flex align-items-center justify-content-center mb-3 industry-icon-card">
                    {/* <i className="fa fa-users-cog text-white"></i> */}
                    <FontAwesomeIcon icon={faBook} style={{ color: '00C389' }} size='3x' />
                  </div>
                  <h4>Recommendation Systems</h4>
                  <p className="mb-0">Personalized recommendations, content filtering</p>
                </div>
                <div className="col-12 wow zoomIn" data-wow-delay="0.8s">
                  <div className=" rounded d-flex align-items-center justify-content-center mb-3 industry-icon-card">
                    <FontAwesomeIcon icon={faChartLine} style={{ color: '00C389' }} size='3x' />
                  </div>
                  <h4>Predictive Analytics</h4>
                  <p className="mb-0">Forecasting, anomaly detection, trend analysis</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* ==============How We Drive Business Value============= */}
      <section className="about section-padding prelative" data-scroll-index='1'>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="sectioner-header text-center">
                <h3>How We Drive Business Value</h3>
                <span className="line"></span>
              </div>
              <div className="section-content text-center">
                <div className="row">
                  {/* Icon Box 1 */}
                  <div className="col-md-4">
                    <div className="icon-box wow fadeInUp" data-wow-delay="0.2s">
                      <FontAwesomeIcon icon={faMoneyBillAlt} size='2x' className='foundation-model-icon' />
                      <h5>Cost-Efficiency</h5>
                      <p>Leveraging foundational models ensures cost-effective AI development while maintaining high performance</p>
                    </div>
                  </div>
                  {/* Icon Box 2 */}
                  <div className="col-md-4">
                    <div className="icon-box wow fadeInUp" data-wow-delay="0.4s">
                      <FontAwesomeIcon icon={faBolt} size='2x' className='foundation-model-icon' />
                      <h5>Accelerated Development</h5>
                      <p>Our foundational models expedite AI solution development, reducing time-to-market for impactful solutions</p>
                    </div>
                  </div>
                  {/* Icon Box 3 */}
                  <div className="col-md-4">
                    <div className="icon-box wow fadeInUp" data-wow-delay="0.6s">
                      <FontAwesomeIcon icon={faShieldAlt} size='2x' className='foundation-model-icon' />
                      <h5>Robust Foundation</h5>
                      <p>Benefit from reliable and extensively tested models that serve as a solid starting point for tailored AI applications</p>
                    </div>
                  </div>
                </div>
                <a href="#" className="about-btn">Learn More</a>
              </div>
            </div>
          </div>
        </div>
      </section>

    </>
  )
}

export default FoundationalModel