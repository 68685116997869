import React from "react";
import Second_section from "../deliveryModel/deliveryModel"
import Home from "../home/home"
import ClientReview from "../clientReview/clientReview";
import Contact from "../contact/contact";
import DeliveryModel from "../deliveryModel/deliveryModel";


const MainLayout =()=>{
    return(
        <div>
        <Home />
        <DeliveryModel/>
        {/* <ClientReview/> */}
        <Contact/>
        </div>
    )
}

export default MainLayout;