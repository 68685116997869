
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoppingCart, faCheck, faCog, faExpandArrowsAlt, faLayerGroup, faUserCog, faFlask, faDatabase, faCode } from '@fortawesome/free-solid-svg-icons';
import React, { useEffect } from 'react'
import './services.css'
const SoftwareDevelopment = () => {

    useEffect(() => {
        window.scrollTo(0, 0);

    }, []);
    return (
        <div style={{ marginTop: '100px' }} className='our-service'>
            <h1 className=" text-uppercase text-center our-service-heading py-4">Custom Software Development</h1>
            <div className="container-fluid ">
                <div className="container py-5">
                    <div className="row g-5 mb-5">
                        <div className="col-xl-6">
                            <div className="row g-4">
                                <div className="col-6">
                                    <img src="https://img.freepik.com/free-vector/programming-concept-illustration_114360-1351.jpg?size=626&ext=jpg" className="img-fluid h-100 wow zoomIn" data-wow-delay="0.1s" alt="" />
                                </div>
                                <div className="col-6">
                                    <img src="https://img.freepik.com/free-vector/software-code-testing-concept-illustration_114360-8414.jpg?size=626&ext=jpg" className="img-fluid pb-3 wow zoomIn" data-wow-delay="0.1s" alt="" />
                                    <img src="https://img.freepik.com/free-vector/ui-ux-designers-isometric-composition-with-small-people-creating-custom-design-web-site-3d-vector-illustration_1284-68939.jpg?size=626&ext=jpg" className="img-fluid pt-3 wow zoomIn" data-wow-delay="0.1s" alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 wow fadeIn" data-wow-delay="0.5s">
                            {/* <h1 className=" text-uppercase our-service-heading">Custom Software Development</h1> */}
                            <p className="pb-4">Step into the future with bespoke software solutions crafted uniquely for your
                                business needs,ensuring seamless integration, efficient operations, and sustainable growth</p>
                            <div className="row g-4 mb-4">
                                <div className="sectioner-header text-center">
                                    <h3 className='our-service-subheading'>Features and Offerings</h3>
                                </div>
                                <div className="col-md-6">
                                    <div className="ps-3 d-flex align-items-center justify-content-start">
                                        <span className="">
                                            <FontAwesomeIcon icon={faCog} size='3x' className='our-service-icon' />
                                        </span>
                                        <div className="ms-4">
                                            <hh6 className='our-service-subheading'>Bespoke Development</hh6>
                                            <p>Tailored software solutions built from the ground up to cater specifically to your requirements</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="ps-3 d-flex align-items-center justify-content-start">
                                        <span className="">
                                            <FontAwesomeIcon icon={faExpandArrowsAlt} size='3x' className='our-service-icon' />
                                        </span>
                                        <div className="ms-4">
                                            <h6 className='our-service-subheading'>Scalable Architectures</h6>
                                            <p>Designs that adapt and grow with your business, ensuring longevity and flexibility</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row g-4 mb-4">
                                <div className="col-md-6">
                                    <div className="ps-3 d-flex align-items-center justify-content-start">
                                        <span className="">
                                            <FontAwesomeIcon icon={faLayerGroup} size='3x' className='our-service-icon' />
                                        </span>
                                        <div className="ms-4">
                                            <h6 className='our-service-subheading'>Modern Tech Stack</h6>
                                            <p>Leverage the latest technologies and frameworks to ensure your software is cutting-edge and robust</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="ps-3 d-flex align-items-center justify-content-start">
                                        <span className="">
                                            <FontAwesomeIcon icon={faUserCog} size='3x' className='our-service-icon' />
                                        </span>
                                        <div className="ms-4">
                                            <h6 className='our-service-subheading'>User Centric Designs</h6>
                                            <p>Ensuring intuitive interfaces and optimal user experience, keeping end-users in mind</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row g-4 mb-4">
                            <div className="col-md-6">
                                    <div className="ps-3 d-flex align-items-center justify-content-start">
                                        <span className="">
                                            <FontAwesomeIcon icon={faCode} size='3x' className='our-service-icon' />
                                        </span>
                                        <div className="ms-4">
                                            <h6 className='our-service-subheading'>Data Science</h6>
                                            <p>Detailed insights into business performance using predictive and perspective modeling with operational data sets.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="ps-3 d-flex align-items-center justify-content-start">
                                        <span className="">
                                            <FontAwesomeIcon icon={faDatabase} size='3x' className='our-service-icon' />
                                        </span>
                                        <div className="ms-4">
                                            <h6 className='our-service-subheading'>Data Processing</h6>
                                            <p>Data cleansing, dedup, enrichment & MDM</p>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>

                            <div className="row g-2">
                                <div className="sectioner-header text-center">
                                    <h3 className='our-service-subheading'>Benefits</h3>
                                </div>
                                <div className="col-md-6">
                                    <p className="mb-2"><FontAwesomeIcon icon={faCheck} className='our-service-icon px-4' />Strategic Alignment</p>
                                    <p className="mb-0"><FontAwesomeIcon icon={faCheck} className='our-service-icon px-4' />Intelligent Decisions</p>
                                    <p className="mb-2"><FontAwesomeIcon icon={faCheck} className='our-service-icon px-4' />Operational Efficiency</p>
                                </div>
                                <div className="col-md-6">
                                    <p className="mb-2"><FontAwesomeIcon icon={faCheck} className='our-service-icon px-4' />Innovative Edge</p>
                                    <p className="mb-0"><FontAwesomeIcon icon={faCheck} className='our-service-icon px-4' />Cost Effective</p>
                                    <p className="mb-0"><FontAwesomeIcon icon={faCheck} className='our-service-icon px-4' />Future Proofing</p>
                                </div>
                                
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default SoftwareDevelopment
