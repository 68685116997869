import React, { useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeartbeat, faBolt, faShoppingCart, faBusinessTime, faIndustry, faUsersRays, faMoneyBill, faBullseye, faUserCog, faCogs } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import './taskSpecificModel.css'

function TaskSpecificModel() {
  useEffect(() => {
    window.scrollTo(0, 0);

  }, []);

  return (
    <div style={{ marginTop: '150px' }}>
      {/* ==================Task Specific Models================= */}
      <div className="container-fluid py-5 my-5">
        <div className="container py-5">
          <div className="row g-5">
            <div className="col-lg-7 col-md-6 col-sm-12 wow fadeIn" data-wow-delay=".5s">
              <h1 className="mb-4">Task Specific Models</h1>
              <p><b>Tailored AI Solutions for Your Unique Business Needs</b></p>
              <p className="mb-4">At Enginuo, we specialize in offering Task-Specific Models, providing precision-crafted AI solutions designed to address specific business challenges and applications across diverse industries</p>
              <a href="" className="btn rounded-pill px-5 py-3 text-white" style={{ backgroundColor: "#00C389" }}>More Details</a>
            </div>
            <div className="col-lg-5 col-md-6 col-sm-12 wow fadeIn" data-wow-delay=".3s">
              <div className="h-100 position-relative">
                <img src="https://imageio.forbes.com/specials-images/imageserve/648aaa9fac6d92c810b12f50/Why-Companies-Are-Vastly-Underprepared-For-The-Risks-Posed-By-AI/960x0.jpg?format=jpg&width=1440" className="img-fluid w-75 rounded" alt="" style={{ marginBottom: '25%' }} />
                <div className="position-absolute w-75" style={{ top: '25%', left: '25%' }}>
                  <img src=" https://s40424.pcdn.co/in/wp-content/uploads/2022/10/What-is-M.Tech-in-Artificial-Intelligence_AI.jpg.webp" className="img-fluid w-100 rounded" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* =====================Features and Offerings======================== */}
      <div className="taskmodel_offer section" id="taskmodel_offer">
        <div className="container ">
          <div className=" text-center">
            <h3>Features and Offerings</h3>
            <span className="line"></span>
          </div>
          <div className="row g-3">
            <div className="col-lg-3 col-md-6">
              <div className="taskmodel_offer-item">
                <div className="icon">
                  <FontAwesomeIcon icon={faCogs} size='4x' className='task-offer-model-icon' />
                </div>
                <div className="main-content">
                  <h4>Specialized Functionality</h4>
                  <p>These models are designed to excel in precise tasks, whether it's natural language understanding, image recognition, anomaly detection, or personalized recommendations</p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="taskmodel_offer-item">
                <div className="icon">
                  <FontAwesomeIcon icon={faUsersRays} size='4x' className='task-offer-model-icon' />
                </div>
                <div className="main-content">
                  <h4>Tailored Precision</h4>
                  <p>Task-Specific Models are crafted to offer precision and accuracy, ensuring optimal performance in specific scenarios or industries</p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="taskmodel_offer-item">
                <div className="icon">
                  <FontAwesomeIcon icon={faBusinessTime} size='4x' className='task-offer-model-icon' />
                </div>
                <div className="main-content">
                  <h4>Customization for Your Business</h4>
                  <p>We customize these models to align seamlessly with your unique business requirements, ensuring that they effectively address your specific challenges</p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="taskmodel_offer-item">
                <div className="icon">
                  <FontAwesomeIcon icon={faBolt} size='4x' className='task-offer-model-icon' />
                </div>
                <div className="main-content">
                  <h4>Rapid Deployment</h4>
                  <p>Leveraging Task-Specific Models expedites the development and deployment of AI solutions, enabling quick adaptation and implementation</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      {/* ====================Industry Applications================== */}
      <section className="service_section layout_padding mt-5">
        <div className="container">
          <div className="heading_container">
            <h2>Industry Applications</h2>
            <p>Our Task-Specific Models cater to a wide array of industry-specific needs, including but not limited to</p>
          </div>
          <div className="row">
            {/* Service Box 1 */}
            <div className="col-md-6 col-lg-3">
              <div className="box">
                <div className="img-box">
                  <FontAwesomeIcon icon={faHeartbeat} size='3x' className='foundation-model-icon' />
                </div>
                <div className="detail-box">
                  <h5>Healthcare</h5>
                  <p>Disease diagnosis, patient risk assessment</p>
                  <Link to={''}>
                    <span>Read More</span>
                    <i className="fa fa-long-arrow-right" aria-hidden="true"></i>
                  </Link>
                </div>
              </div>
            </div>
            {/* Service Box 2 */}
            <div className="col-md-6 col-lg-3">
              <div className="box">
                <div className="img-box">
                  <FontAwesomeIcon icon={faMoneyBill} size='3x' className='foundation-model-icon' />
                </div>
                <div className="detail-box">
                  <h5>Finance</h5>
                  <p>Fraud detection, risk assessment, algorithmic trading</p>
                  <Link to={''}>
                    <span>Read More</span>
                    <i className="fa fa-long-arrow-right" aria-hidden="true"></i>
                  </Link>
                </div>
              </div>
            </div>
            {/* Service Box 3 */}
            <div className="col-md-6 col-lg-3">
              <div className="box">
                <div className="img-box">
                  <FontAwesomeIcon icon={faShoppingCart} size='3x' className='foundation-model-icon' />
                </div>
                <div className="detail-box">
                  <h5>E-commerce</h5>
                  <p>Product recommendation, customer behavior analysis</p>
                  <Link to={''}>
                    <span>Read More</span>
                    <i className="fa fa-long-arrow-right" aria-hidden="true"></i>
                  </Link>
                </div>
              </div>
            </div>
            {/* Service Box 4 */}
            <div className="col-md-6 col-lg-3">
              <div className="box">
                <div className="img-box">
                  <FontAwesomeIcon icon={faIndustry} size='3x' className='foundation-model-icon' />
                </div>
                <div className="detail-box">
                  <h5>Manufacturing</h5>
                  <p>Predictive maintenance, quality control, supply chain optimization</p>
                  <Link to={''}>
                    <span>Read More</span>
                    <i className="fa fa-long-arrow-right" aria-hidden="true"></i>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="btn-box">
            <Link to={''}>View More</Link>
          </div>
        </div>
      </section>
      {/* ==============How We Drive Business Value============= */}
      <section className="about section-padding prelative" data-scroll-index='1'>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="sectioner-header text-center">
                <h3>How We Drive Business Value</h3>
                <span className="line"></span>
              </div>
              <div className="section-content text-center">
                <div className="row">
                  {/* Icon Box 1 */}
                  <div className="col-md-4">
                    <div className="icon-box wow fadeInUp" data-wow-delay="0.2s">
                      <FontAwesomeIcon icon={faBullseye} size='2x' className='foundation-model-icon' />
                      <h5>Precision and Efficiency</h5>
                      <p>Task-Specific Models ensure accuracy and efficiency in performing specific tasks, enhancing operational effectiveness</p>
                    </div>
                  </div>
                  {/* Icon Box 2 */}
                  <div className="col-md-4">
                    <div className="icon-box wow fadeInUp" data-wow-delay="0.4s">
                      <FontAwesomeIcon icon={faUserCog} size='2x' className='foundation-model-icon' />
                      <h5>Custom-Tailored Solutions</h5>
                      <p>Our models are customized to suit your business context, ensuring a perfect fit for your unique challenges and requirements</p>
                    </div>
                  </div>
                  {/* Icon Box 3 */}
                  <div className="col-md-4">
                    <div className="icon-box wow fadeInUp" data-wow-delay="0.6s">
                      <FontAwesomeIcon icon={faCogs} size='2x' className='foundation-model-icon' />
                      <h5>Streamlined Solutions Development</h5>
                      <p>Leveraging these models expedites AI solution development and deployment, translating into faster results and ROI</p>
                    </div>
                  </div>
                </div>
                <a href="#" className="about-btn">Learn More</a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default TaskSpecificModel