import React,{useEffect} from 'react';
import './career.css'
const CareersPage = () => {

  // Scroll to the top of the component when the component mounts
  useEffect(() => {
    window.scrollTo(0, 0);

  }, []);
  return (
    
    <section className="careers-page clear pt-5 pb-4">
      <div className="page-title hero-image">
        <h1 >Careers</h1>
      </div>
      <div className="page-content clear" style={{ backgroundImage: 'url(../../../../../assets/images/strips.jpg)' }}>
        <div className="container">
          <aside className="float-left">
            <nav>
              <ul data-aos-delay="100" data-aos="fade" className="aos-init aos-animate">
                <li data-aos="fade-right" data-aos-delay="200" className="aos-init aos-animate">
                  <a style={{ color: '#00c389', fontWeight: 900 }} href="/careers" >Careers</a>
                </li>
              </ul>
            </nav>
          </aside>
          <div className="careers-content">
            <h2 data-aos-delay="200" data-aos="fade-up" className="join-heading aos-init aos-animate" style={{ color: '#00c389'}}>Join Enginuo</h2>
            <p data-aos-delay="200" data-aos="fade-up" className="aos-init aos-animate">
            Enginuo handholds your enthusiasm to reach the extraordinary. Recruitment is in our blood, so if you’re ambitious, fun-loving and curious, explore our open roles for working totally remotely, from the office or somewhere in between
            We provide an environment where you can have your own space to grow 
            and express. Join us to grow together. Are you ready to apply your knowledge and background to exciting new challenges? From learning to leadership, this is
             your chance to take your career to the next level. Search and apply for a job today
              <br /><br />
              Please view our current opportunities at the link below.
              Should you have any questions or need assistance with the application process please contact Human Resources at <a href="mailto:careers@enginuo.com">careers@enginuo.com</a>.
              <br />
            </p>
            <a target="_blank" href="/careers" className="button red" style={{ textDecoration: 'none' }}>View Current Opportunities</a>
            <div className="container card d-flex justify-content-center mt-5">
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CareersPage;
