import 'bootstrap/dist/css/bootstrap.min.css';
import Footer from './Components/common/footer/footer';
import CareersPage from './Components/careers/careers';
import { Navigate, Route, Routes } from 'react-router-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import MainLayout from './Components/mainLayout/mainLayout';
import Header from './Components/common/header/header';
import About from './Components/about/about'
import './App.css'
import Contact from './Components/contact/contact';
import Services from './Components/services/services';
import Products from './Components/products/products';
import FoundationalModel from './Components/aiSolutions/foundationalModel/foundationalModel';
import GenAiPlatform from './Components/aiSolutions/genAiPlatform/genAiPlatform';
import OpensourceModel from './Components/aiSolutions/opensourceModel/opensourceModel';
import TaskSpecificModel from './Components/aiSolutions/taskSpecificModel/taskSpecificModel';
import SocialIcons from './Components/common/socialIcons/socialIcons';
import AiSolutionHome from './Components/aiSolutions/aiSolutionHome/aiSolutionHome';
import CtoService from './Components/services/ctoService';
import DevopsService from './Components/services/devopsService';
import SoftwareDevelopment from './Components/services/softwareDevelopment';
import DataAnalytics from './Components/services/dataAnalytics';
import AwsManagedService from './Components/services/awsManagedService';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
function App() {
  return (
    <Router>
      <div className="App">
      <ToastContainer/>
        <Header/>
        {/* <SocialIcons/> */}
        <Routes>
          <Route path='/' element={<MainLayout/>} />
          <Route path='/careers' element={<CareersPage />} />
          <Route path='/contactus' element={<Contact/>} />
          <Route path='/about' element={<About/>} />
          <Route path='/services' element={<Services/>} />
          <Route path='/cto' element={<CtoService/>} />
          <Route path='/softwaredevelopment' element={<SoftwareDevelopment/>} />
          <Route path='/dataanalytics' element={<DataAnalytics/>} />
          <Route path='/aws' element={<AwsManagedService/>} />
          <Route path='/devops' element={<DevopsService/>} />

          <Route path='/products' element={<Products/>} />
          <Route path='/products' element={<Products/>} />
          <Route path='/aisolution' element={<AiSolutionHome/>} />
          <Route path='/genai' element={<GenAiPlatform/>} />
          <Route path='/foundational' element={<FoundationalModel/>} />
          <Route path='/opensource' element={<OpensourceModel/>} />
          <Route path='/taskspecific' element={<TaskSpecificModel/>} />
          <Route path='*' element={<Navigate to='/' replace />} />
        </Routes>

        <Footer />
      </div>
    </Router>
  );
}

export default App;
