import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faShoppingCart,  faCheck, faCloudUploadAlt, faChartBar, faWarehouse, faCode, faDatabase, faCog } from '@fortawesome/free-solid-svg-icons';
import React,{useEffect}from 'react'
import './services.css'
const DataAnalytics=()=> {

    useEffect(() => {
        window.scrollTo(0, 0);
    
      }, []);
    return (
        <div style={{ marginTop: '100px' }} className='our-service'>
            <div className="container-fluid ">
                <div className="container py-5">
                    <div className="row g-5 mb-5">
                        
                        <div className="col-xl-6 wow fadeIn" data-wow-delay="0.5s">
                            <h1 className=" text-uppercase our-service-heading">Data & Analytics</h1>
                            <p className="pb-4">Our best-of-breed data and analytical services help our customers to transform raw data to meaningful insights to drive strategy, achieve competitive advantage and business performance</p>
                            <div className="row g-4 mb-4">
                                <div className="sectioner-header text-center">
                                    <h3 className='our-service-subheading'>Features and Offerings</h3>
                                </div>
                                <div className="col-md-6">
                                    <div className="ps-3 d-flex align-items-center justify-content-start">
                                        <span className="">
                                            <FontAwesomeIcon icon={faCloudUploadAlt} size='3x' className='our-service-icon'/>
                                        </span>
                                        <div className="ms-4">
                                            <hh6 className='our-service-subheading'>Data Ingestion</hh6>
                                            <p>Seamlessly move data from source to destination. ETl & ELT solutions with real-time, near real-time & batch modes.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="ps-3 d-flex align-items-center justify-content-start">
                                        <span className="">
                                            <FontAwesomeIcon icon={faChartBar} size='3x' className='our-service-icon' />
                                        </span>
                                        <div className="ms-4">
                                            <h6 className='our-service-subheading'>Business Intelligence & Visualization</h6>
                                            <p>Our knowledge of AI-enabled visualization tools helps customers create ad-hoc reports in natural texts</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row g-4 mb-4">
                                <div className="col-md-6">
                                    <div className="ps-3 d-flex align-items-center justify-content-start">
                                        <span className="">
                                            <FontAwesomeIcon icon={faWarehouse} size='3x' className='our-service-icon'/>
                                        </span>
                                        <div className="ms-4">
                                            <h6 className='our-service-subheading'>Lakehouse Architecture</h6>
                                            <p>Modern lake house architecture helps enterprises to set up a unified platform for data warehouse, data lake & data governance to cater to both BI intelligence and Data</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="ps-3 d-flex align-items-center justify-content-start">
                                        <span className="">
                                            <FontAwesomeIcon icon={faCode} size='3x' className='our-service-icon'/>
                                        </span>
                                        <div className="ms-4">
                                            <h6 className='our-service-subheading'>Data Science</h6>
                                            <p>Detailed insights into business performance using predictive and perspective modeling with operational data sets</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row g-4 mb-4">
                                <div className="col-md-6">
                                    <div className="ps-3 d-flex align-items-center justify-content-start">
                                        <span className="">
                                            <FontAwesomeIcon icon={faDatabase} size='3x' className='our-service-icon'/>
                                        </span>
                                        <div className="ms-4">
                                            <h6 className='our-service-subheading'>Data Warehouse</h6>
                                            <p>Storage optimized Data Warehouse setup by Extract, Load & Transform (ELT)</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="ps-3 d-flex align-items-center justify-content-start">
                                        <span className="">
                                            <FontAwesomeIcon icon={faCog} size='3x' className='our-service-icon'/>
                                        </span>
                                        <div className="ms-4">
                                            <h6 className='our-service-subheading'>Data Processing</h6>
                                            <p>Data cleansing, dedup, enrichment & MDM</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            
                        </div>

                        <div className="col-xl-6">
                            <div className="row g-4">
                                
                                <div className="col-6">
                                    <img src="https://img.freepik.com/free-vector/site-stats-concept-illustration_114360-1434.jpg?size=626&ext=jpg" className="img-fluid pb-3 wow zoomIn" data-wow-delay="0.1s" alt="" />
                                    <img src="https://img.freepik.com/free-vector/people-analyzing-growth-charts_23-2148866843.jpg?size=626&ext=jpg" className="img-fluid pt-3 wow zoomIn" data-wow-delay="0.1s" alt="" />
                                </div>
                                <div className="col-6">
                                    <img src="https://img.freepik.com/free-photo/office-workers-using-finance-graphs_23-2150408668.jpg?size=626&ext=jpg" className="img-fluid h-100 wow zoomIn" data-wow-delay="0.1s" alt="" />
                                </div>
                            </div>
                            <div className="row g-2 mt-5">
                            <div className="sectioner-header text-center">
                                    <h3 className='our-service-subheading'>Benefits</h3>
                                </div>
                                <div className="col-md-6">
                                    <p className="mb-2"><FontAwesomeIcon icon={faCheck} className='our-service-icon px-4' />Productivity & Efficiency</p>
                                    <p className="mb-0"><FontAwesomeIcon icon={faCheck} className='our-service-icon px-4' />Competitive Advantage</p>
                                </div>
                                <div className="col-md-6">
                                    <p className="mb-2"><FontAwesomeIcon icon={faCheck} className='our-service-icon px-4' />Risk Management</p>
                                    <p className="mb-0"><FontAwesomeIcon icon={faCheck} className='our-service-icon px-4' />Detailed Market Study</p>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
    )
}

export default DataAnalytics
