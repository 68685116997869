import React, { useState } from "react";
import "./deliveryModel.css";
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import podModel from "../../Assets/podModel.svg";

 
const DeliveryModel = () => {
 
 
 
  return (
    <Container className="pb-5">
      <h1 className="hr-lines">
        Concord <span id="span">Delivery Model </span>{" "}
      </h1>
 
      <Row className="carde">
        {/* <h4> Redefining Value Delivery With Product Centric Development</h4> */}
        <img src={podModel} alt="podModel" />
      </Row>
 
{/* why make us unique */}
 
      <h1 className="hr-lines">
        What make us <span id="span">unique</span> ?
      </h1>
 
      <Row className="carde">
        <Col md={4}>
          <div >
            <Card style={{ border: "none" }} className="selected ">
              <Card.Img variant="top" src={require("../../Assets/card1.png")} />
              <Card.Body>
                <Card.Text>
                  As a digital native company, we deliver consulting and
                  software services with agility, flexibility and expertise. We
                  have highly trained pool of talent that provides world class
                  business solutions
                </Card.Text>
              </Card.Body>
            </Card>
          </div>
        </Col>
 
        <Col md={4}>
        <div  >
            <Card style={{ border: "none" }} className="selected  ">
              <Card.Img variant="top" src={require("../../Assets/card2.png")} />
              <Card.Body>
                <Card.Text>
                  We are true partners not vendors, We value our customer as
                  true partners Vertical Industry Experience
                </Card.Text>
              </Card.Body>
            </Card>
          </div>
        </Col>
 
        <Col md={4} >
        <div  >
            <Card style={{ border: "none" }} className="selected ">
              <Card.Img variant="top" src={require("../../Assets/card3.png")} />
              <Card.Body>
                <Card.Text>
                  We have highly trained pool of talent that provides world
                  class business solutions, Offshore and onsite presence,
                  service to our customers Unique, Employee Journey A+ Work{" "}
                </Card.Text>
              </Card.Body>
            </Card>
          </div>
        </Col>
      </Row>
      <Row className="carde">
        <Col md={5}>
          <h1 className="hr-lines">
            Why <span id="span">Enginuo</span> ?
          </h1>
          <h5>
          <p>
          The core mission is to give every business its self-delivering arm of technology expertise. 
          We provide solutions for the challenges in companies to have IT teams that need skills for 
          the new technology emerging market, To Drive advantages that’s centered around technologies such as AI, Data, Cloud, etc
          </p>
          </h5>
 
          
        </Col>
 
        <Col md={7}>
          <div className="image_back">
            <img
              src={require("../../Assets/image1.png")}
              className="image"
              alt=""
            />
          </div>
        </Col>
      </Row>
    </Container>
  );
};
export default DeliveryModel;