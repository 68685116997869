import React, { useEffect } from 'react'
import './aiSolutionHome.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
const AiSolutionHome = () => {


    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div style={{ marginTop: '150px' }}>
            <div className='mt-5'>
                <div className="container-xxl ">
                    <div className="container">
                        <div className="row g-5">
                            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
                                {/* <div className="position-relative overflow-hidden ps-5 pt-5 h-100" style={{ minHeight: '400px' }}>
                <img className="position-absolute w-100 h-100" src="https://cdn.dribbble.com/users/318273/screenshots/3403867/ai_circuits_dribbble.gif" alt="" style={{ objectFit: 'cover' }} />
                <div className="position-absolute top-0 start-0 bg-white pe-3 pb-3" style={{ width: '200px', height: '200px' }}>
                  <div className="d-flex flex-column justify-content-center text-center bg-primary h-100 p-3">
                  <h1 className="text-white">25</h1>
                  <h2 className="text-white">Years</h2>
                  <h5 className="text-white mb-0">Experience</h5>
                </div>
                </div>
              </div> */}

                                <div className="position-relative overflow-hidden ps-5 pt-5 h-100" style={{ minHeight: '400px' }}>
                                    <img className="position-absolute w-100 h-100" src="https://cdn.dribbble.com/users/318273/screenshots/3403867/ai_circuits_dribbble.gif" alt="" style={{ objectFit: 'cover' }} />

                                </div>


                            </div>
                            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.5s">
                                <div className="h-100">
                                    <div className="border-start border-5 border-primary ps-4 mb-5" >
                                        <h1 className="display-6 mb-0">AI Solutions</h1>
                                    </div>
                                    <p><FontAwesomeIcon icon={faAngleRight} className="text-primary me-2" /> Enginuo is pioneering the next wave of innovation through cutting-edge AI solutions tailored to empower businesses across diverse industries. We understand that the future of enterprise lies
                                        in harnessing the power of Artificial Intelligence to drive efficiency, intelligence, and growth.</p>
                                    <p className="mb-4"><FontAwesomeIcon icon={faAngleRight} className="text-primary me-2" /> Our vision is to revolutionize how businesses operate by leveraging the potential of AI. We aspire to be at the forefront of technological advancement, shaping the landscape
                                        of industries by delivering innovative, scalable, and impactful AI solutions.</p>
                                        
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="container-fluid my-5 p-0">
                    <div className="mx-auto text-center wow fadeIn" data-wow-delay="0.1s" style={{ maxWidth: '500px' }}>
                        <b><h1 className="mb-4" style={{ color: '#00C389' }}>Enginuo’s Tailored AI Solutions</h1></b>
                    </div>
                    <div className="row g-0">
                        <div className="col-xl-3 col-sm-6 wow fadeIn " data-wow-delay="0.1s">
                            <div className="position-relative facts-card">
                                <img className="img-fluid " src="https://img.freepik.com/free-vector/ai-technology-microchip-background-vector-digital-transformation-concept_53876-112222.jpg?size=626&ext=jpg" alt="" />
                                <div className="facts-overlay">
                                    <h1 className="display-1">01</h1>
                                    <h4 className="text-white mb-3 ">GenAI Developer Platform</h4>
                                    <p className="text-white">Unlock the full potential of AI innovation with our GenAI Developer Platform</p>

                                    <Link to="/genai" className="text-white small">
                                        READ MORE
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-sm-6 wow fadeIn " data-wow-delay="0.3s">
                            <div className="position-relative facts-card">
                                <img className="img-fluid" src="https://img.freepik.com/free-photo/ai-nuclear-energy-industry-innovation-smart-grid-disruptive-technology_53876-143121.jpg?size=626&ext=jpg" alt="" />
                                <div className="facts-overlay">
                                    <h1 className="display-1">02</h1>
                                    <h4 className="text-white mb-3">Foundational Models</h4>
                                    <p className="text-white">Unleashing the Power of AI Foundation for Your Business</p>
                                    <Link to="/foundational" className="text-white small">
                                        READ MORE
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-sm-6 wow fadeIn" data-wow-delay="0.5s">
                            <div className="position-relative facts-card">
                                <img className="img-fluid " src="https://img.freepik.com/free-vector/ai-technology-brain-background-vector-digital-transformation-concept_53876-112223.jpg?size=626&ext=jpg" alt="" />
                                <div className="facts-overlay">
                                    <h1 className="display-1">03</h1>
                                    <h4 className="text-white mb-3">Task Specific Models</h4>
                                    <p className="text-white">Unleashing the Power of AI Foundation for Your Business</p>
                                    <Link to="/taskspecific" className="text-white small">
                                        READ MORE
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-sm-6 wow fadeIn" data-wow-delay="0.7s">
                            <div className="position-relative facts-card">
                                <img className="img-fluid " src="https://img.freepik.com/free-photo/ai-robot-frame-technology-abstract-futuristic-tech-design-with-blank-space_53876-129790.jpg?size=626&ext=jpg" alt="" />
                                <div className="facts-overlay">
                                    <h1 className="display-1">04</h1>
                                    <h4 className="text-white mb-3">OpenSource Models</h4>
                                    <p className="text-white">Empowering Innovation with Collaborative AI Solutions</p>
                                    <Link to="/opensource" className="text-white small">
                                        READ MORE
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default AiSolutionHome
