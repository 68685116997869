import React, { useEffect, useState } from 'react';
import databricks from "../../Assets/db.png";
import aws from "../../Assets/Amazon_Web_Services_logo.svg";
import snowFlake from "../../Assets/Snowflake_Inc.-Logo.svg";
import microSoft from "../../Assets/Microsoft-Azure-Logo.svg";
import googleCloud from "../../Assets/google_cloud-logo.svg";
import Asia from "../../Assets/asia.svg";
import northAmerica from "../../Assets/north-america.svg";
import Ourpartners from "../../Assets/partners.svg";
import './about.css';
// import {Row} from 'react-bootstrap';
import ourValues from '../../Assets/ourValues.svg'
const About = () => {
  // Scroll to the top of the component when the component mounts
  useEffect(() => {
    window.scrollTo(0, 0);

  }, []);
  const [activeTab, setActiveTab] = useState('northAmerica');

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };
  const [activePosition, setActivePosition] = useState(4);

  const partners = [
    {
      id: 1,
      image: databricks,
      description:
        'Databricks is an industry-leading, cloud-based data engineering tool used for processing and transforming massive quantities of data and exploring the data through machine learning models.',

    },
    {
      id: 2,
      image: snowFlake,
      description:
        'Snowflake enables data storage, processing, and analytic solutions that are faster, easier to use, and far more flexible than traditional offerings.',

    },
    {
      id: 6,
      image: aws,
      description:
        'Amazon Web Services (AWS) is the worlds most comprehensive and broadly adopted cloud, offering over 200 fully featured services from data centers globally.',

    },
    {
      id: 4,
      image: googleCloud,
      description:
        'Google Cloud Platform (GCP), offered by Google, is a suite of cloud computing services that provides a series of modular cloud services including computing, data storage, data analytics and machine learning, alongside a set of management tools.',

    },
    {
      id: 5,
      image: microSoft,
      description:
        'The Azure cloud platform is more than 200 products and cloud services designed to help you bring new solutions to life—to solve today’s challenges and create the future. Build, run, and manage applications across multiple clouds, on-premises, and at the edge, with the tools and frameworks of your choice',

    },
  ];

  const handleClientClick = (position) => {
    setActivePosition(position);
  };
  return (
    <>
      <div className='container-aboutus'>
        {/* <Row> */}
        <div className='content-container'>
          <div className='text-container'>
            <h1 className='hr-lines' style={{ color: '#FFFFFF', fontSize: 50 }}>
              What We <span id='span'>Do </span>
            </h1>
            <p style={{ fontSize: '18px', color: 'white', fontWeight: '300', maxWidth: '500px' }}>
              <i><b style={{ fontSize: '22px', color: '#00c389' }}>Enginuo</b> is a leading Global Digital transformation and product
                development service firm. We do technology consulting and software services with a range of IT services to clients across various industries, We focus on tomorrow’s technology and digital outcomes, build them
                now for Quicker Delivery than interruptions. Enginuo specializes in
                Platform agnostic transformation, delivering Custom-BeSpoke
                Engagement and Technology products. Enginuo handles deep
                industry-driven processes and system re-engineering through Digital
                transformation. The core mission is to give every business its
                self-delivering arm of technology expertise. We provide solutions
                for the challenges in companies to have IT teams that need skills
                for the new technology emerging market. To Drive advantages that’s
                centered around technologies such as AI, Data, Cloud, etc
              </i></p>
          </div>
          <div className='image-container'>
            <img src={require('../../Assets/image1.png')} className='image' alt="" />
          </div>
        </div>
        <div className="container-about">
          <div className='about-heading'>
            <h1 className='hr-lines' style={{ color: 'black' }}>
              Our <span id='span'>Values</span>
            </h1>
          </div>

          <div className='ourValues-image'>
            {/* <Image src={require('../../Assets/ourValues.svg')} style={{ width: "", height: "" }} fluid></Image> */}
            <img src={ourValues} alt="ourValues" className='pb-5' />
          </div>
        </div>

      </div>


      {/* Our partners */}


      <section className="testimonial-area">
        <div className='about-heading'>
          <h1 className='hr-lines' style={{ color: 'black' }}>
            Our <span id='span'>Partners</span>
          </h1>
        </div>
        <div>
          <div className="container ">
            <div className='container  text-center d-flex align-items-center' style={{display:'flex',flexWrap:'wrap'}}>
              <img src={Ourpartners} alt=""  className="mx-auto" style={{ maxWidth: '70%', height: 'auto' }}/>
            </div>


            {/* <div className='partners-slides'>
              <div className='slide-track'>
                {partners.map((partners) => (
                  <div key={partners.id} >
                    <div class="partners-slide">
                      <img src={partners.image} alt="" />
                    </div>
                  </div>
                ))}

                {partners.map((partners) => (
                  <div key={partners.id} >
                    <div class="partners-slide">
                      <img src={partners.image} alt="" />
                    </div>
                  </div>
                ))}

              </div>
            </div> */}


          </div>
        </div>
      </section>




      {/* our locations */}
      <div className='container-locations'>
        <div className='about-heading'>
          <h1 className='hr-lines' style={{ color: 'black' }}>
            Global <span id='span'>Presence</span>
          </h1>
        </div>
        <nav>
          <ul>
            <li className={activeTab === 'northAmerica' ? 'active' : ''} onClick={() => handleTabClick('northAmerica')}>
              North America
            </li>
            <li className={activeTab === 'asia' ? 'active' : ''} onClick={() => handleTabClick('asia')}>
              Asia
            </li>
          </ul>
        </nav>
        <div>
          {activeTab === 'northAmerica' && <div className='text-center m-3' > <img src={northAmerica} alt="northAmerica" style={{ height: '50%', width: '50%' }} /></div>}
          {activeTab === 'asia' && <div className='text-center m-3'><img src={Asia} alt="asia" style={{ height: '50%', width: '50%' }} /></div>}
        </div>
      </div>
    </>
  );
};

export default About;
