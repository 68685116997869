import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import env from "react-dotenv";



const root = ReactDOM.createRoot(document.getElementById('root'));
console.log(process.env)
root.render(
  <React.StrictMode>
    <App />
    
  </React.StrictMode>
);

