import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoppingCart, faCheck, faCodeBranch, faChartLine, faCode, faProjectDiagram, faTools, faHandHoldingUsd, faArrowsAltH, faInfinity, faWrench } from '@fortawesome/free-solid-svg-icons';
import React, { useEffect } from 'react'
import './services.css'
const DevopsService = () => {

    useEffect(() => {
        window.scrollTo(0, 0);

    }, []);
    return (
        <div style={{ marginTop: '100px' }} className='our-service'>
            <div className="container-fluid ">
                <div className="container py-5">
                    <div className="row g-5 mb-5">
                        <div className="col-xl-6">
                            <div className="row g-4">
                                <h1 className=" text-uppercase our-service-heading">Managed DevOps</h1>
                                <p className="pb-4">Integrate, automate, and innovate with our Managed DevOps Service. We make the bridge between development and operations seamless, ensuring faster deployments, reduced errors,& improved code quality</p>
                                <div className="col-6">
                                    <img src="https://img.freepik.com/free-vector/isometric-devops-illustration_1284-72753.jpg?size=626&ext=jpg" className="img-fluid h-100 wow zoomIn" data-wow-delay="0.1s" alt="" />
                                </div>
                                <div className="col-6">
                                    <img src="https://img.freepik.com/free-vector/devops-engineer-flat-design-concept-with-big-infinity-symbol-centre-little-icons-working-employees-vector-illustration_1284-74955.jpg?size=626&ext=jpg" className="img-fluid pb-3 wow zoomIn" data-wow-delay="0.1s" alt="" />
                                    <img src="https://img.freepik.com/free-vector/development-operations-banner-devops-concept_107791-2220.jpg?size=626&ext=jpg" className="img-fluid pt-3 wow zoomIn" data-wow-delay="0.1s" alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 wow fadeIn" data-wow-delay="0.5s">

                            <div className="row g-4 mb-4">
                                <div className="sectioner-header text-center">
                                    <h3 className='our-service-subheading'>Features and Offerings</h3>
                                </div>
                                <div className="col-md-6">
                                    <div className="ps-3 d-flex align-items-center justify-content-start">
                                        <span className="">
                                            <FontAwesomeIcon icon={faCodeBranch} size='3x' className='our-service-icon' />
                                        </span>
                                        <div className="ms-4">
                                            <hh6 className='our-service-subheading'>Continuous Integration (CI)</hh6>
                                            <p>Regularly merge code changes into a central repository, followed by automated builds and test</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="ps-3 d-flex align-items-center justify-content-start">
                                        <span className="">
                                            <FontAwesomeIcon icon={faChartLine} size='3x' className='our-service-icon' />
                                        </span>
                                        <div className="ms-4">
                                            <h6 className='our-service-subheading'>Real time Monitoring</h6>
                                            <p>Monitor applications and infrastructure health 24/7 to ensure peak performance</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row g-4 mb-4">
                                <div className="col-md-6">
                                    <div className="ps-3 d-flex align-items-center justify-content-start">
                                        <span className="">
                                            <FontAwesomeIcon icon={faInfinity} size='3x' className='our-service-icon' />
                                        </span>
                                        <div className="ms-4">
                                            <h6 className='our-service-subheading'>Continuous Delivery (CD)</h6>
                                            <p>Automated code release process, ensuring faster and more consistent deployments</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="ps-3 d-flex align-items-center justify-content-start">
                                        <span className="">
                                            <FontAwesomeIcon icon={faWrench} size='3x' className='our-service-icon' />
                                        </span>
                                        <div className="ms-4">
                                            <h6 className='our-service-subheading'>Proactive Maintenance</h6>
                                            <p>Monthly free Virtual Solutions Architect (VSA) Hours forarchitectural guidance and reviews </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row g-4 mb-4">
                                <div className="col-md-6">
                                    <div className="ps-3 d-flex align-items-center justify-content-start">
                                        <span className="">
                                            <FontAwesomeIcon icon={faCode} size='3x' className='our-service-icon' />
                                        </span>
                                        <div className="ms-4">
                                            <h6 className='our-service-subheading'>Infrastructure as Code (laC)</h6>
                                            <p>Manage and provision infrastructure through machine readable files rather than manual processes</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="ps-3 d-flex align-items-center justify-content-start">
                                        <span className="">
                                            <FontAwesomeIcon icon={faProjectDiagram} size='3x' className='our-service-icon' />
                                        </span>
                                        <div className="ms-4">
                                            <h6 className='our-service-subheading'>Architectural Guidance</h6>
                                            <p>Monthly free Virtual Solutions Architect (VSA) Hours for architectural guidance and reviews  </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* <div className="row g-2">
                                <div className="sectioner-header text-center">
                                    <h3 className='our-service-subheading'>Benefits</h3>
                                </div>
                                <div className="col-md-6">
                                    <p className="mb-2"><FontAwesomeIcon icon={faCheck} className='our-service-icon px-4' />Faster Deployments </p>
                                    <p className="mb-0"><FontAwesomeIcon icon={faCheck} className='our-service-icon px-4' />Scalable solutions </p>
                                    <p className="mb-2"><FontAwesomeIcon icon={faCheck} className='our-service-icon px-4' />Collaboration Workflows</p>

                                </div>
                                <div className="col-md-6">
                                    <p className="mb-2"><FontAwesomeIcon icon={faCheck} className='our-service-icon px-4' />Reduced Errors</p>
                                    <p className="mb-0"><FontAwesomeIcon icon={faCheck} className='our-service-icon px-4' />Cost Effective</p>
                                </div>

                            </div> */}
                        </div>
                    </div>
                    <div className="row g-2 col-md-6">
                                <div className="sectioner-header text-center">
                                    <h3 className='our-service-subheading'>Benefits</h3>
                                </div>
                                <div className="col-md-6">
                                    <p className="mb-2"><FontAwesomeIcon icon={faCheck} className='our-service-icon px-4' />Faster Deployments </p>
                                    <p className="mb-0"><FontAwesomeIcon icon={faCheck} className='our-service-icon px-4' />Scalable solutions </p>
                                    <p className="mb-2"><FontAwesomeIcon icon={faCheck} className='our-service-icon px-4' />Collaboration Workflows</p>

                                </div>
                                <div className="col-md-6">
                                    <p className="mb-2"><FontAwesomeIcon icon={faCheck} className='our-service-icon px-4' />Reduced Errors</p>
                                    <p className="mb-0"><FontAwesomeIcon icon={faCheck} className='our-service-icon px-4' />Cost Effective</p>
                                </div>

                            </div>

                </div>
            </div>
        </div>
    )
}

export default DevopsService
