import React, { useEffect } from 'react'
import './genAiPlatform.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faCheck,faAngleRight,faRobot,faCogs ,faCode,faLightbulb } from '@fortawesome/free-solid-svg-icons';
function GenAiPlatform() {
  useEffect(() => {
    window.scrollTo(0, 0);

  }, []);
  return (
    <div style={{ marginTop: "150px" }}>
      {/* ------------------------------------------------ */}


      {/* -----------------GenAI Developer Platform------------------------------- */}
      <div className="container-fluid py-5">
        <div className="container">
          <div className="row g-5 align-items-center">
            <div className="col-lg-6 wow fadeIn" data-wow-delay="0.1s">
              <div className="about-img">
                <img className="img-fluid" src="https://ichef.bbci.co.uk/news/976/cpsprodpb/14202/production/_108243428_gettyimages-871148930.jpg.webp" alt="About Us" />
              </div>
            </div>
            <div className="col-lg-6 wow fadeIn" data-wow-delay="0.5s">
              <h1 className="mb-4"><b>GenAI Developer Platform</b></h1>
              <p className="mb-4"><FontAwesomeIcon icon={faAngleRight} className="text-primary me-2" />Your Gateway to Tailored AI Solutions. Unlock the full potential of AI innovation with our GenAI Developer Platform.</p>
              <p className="mb-4"><FontAwesomeIcon icon={faAngleRight} className="text-primary me-2" />Enginuo provide a comprehensive solution service by utilizing our GenAI Dev Platform Service</p>
              <p className="mb-4"><FontAwesomeIcon icon={faAngleRight} className="text-primary me-2" />Our Platform is designed to empower businesses by providing a robust suite of tools and resources, enabling the seamless development, customization and deployment of AI Solutions</p>

              
            </div>
          </div>
        </div>
      </div>

      {/* -------------------What Sets Us Apart----------------------------- */}

      <div className="container-fluid bg-light ">
        <div className="container py-5">
          <div className="row g-5 align-items-center">
            <div className="col-lg-5 wow fadeIn" data-wow-delay="0.1s">
              <h1 className="mb-4">What Sets Us Apart?</h1>
              <p className="mb-4">At Enginuo, we understand the pivotal role AI Plays in transforming businesses. Our GenAI Developer platform stands out for several key reasons</p>
              <a className="btn btn-primary rounded-pill px-4" href="">Read More</a>
            </div>
            <div className="col-lg-7">
              <div className="row g-4">
                <div className="col-md-6">
                  <div className="row g-4">
                    <div className="col-12 wow fadeIn" data-wow-delay="0.1s">
                      <div className="service-item d-flex flex-column justify-content-center text-center rounded">
                        <div className="service-icon btn-square">
                          <FontAwesomeIcon icon={faCode} size="3x"  style={{padding:'19px',color:'#00C389'}}/>
                        </div>
                        <h5 className="mb-3">Tailored Solutions</h5>
                        <p>We recognize that every business has unique needs. Our platform allows for the development of highly customized AI Models, tailored specially to address your challenges and business objectives</p>
                        <a className="btn px-3 mt-auto mx-auto" href="">Read More</a>
                      </div>
                    </div>
                    <div className="col-12 wow fadeIn" data-wow-delay="0.5s">
                      <div className="service-item d-flex flex-column justify-content-center text-center rounded">
                        <div className="service-icon btn-square">
                          <FontAwesomeIcon icon={faCheck}  size='3x' style={{padding:'19px',color:'#00C389'}} />
                        </div>
                        <h5 className="mb-3">Scalable Infrastructure</h5>
                        <p>Leveraging robust computing infrastructure and cloud capabilities, our platform ensures scalability and efficiency, accommodating complex AI tasks and large datasets.</p>
                        <a className="btn px-3 mt-auto mx-auto" href="">Read More</a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 pt-md-4">
                  <div className="row g-4">
                    <div className="col-12 wow fadeIn" data-wow-delay="0.3s">
                      <div className="service-item d-flex flex-column justify-content-center text-center rounded">
                        <div className="service-icon btn-square">
                        <FontAwesomeIcon icon={faLightbulb} size="3x"  style={{padding:'19px',color:'#00C389'}} />
                        </div>
                        <h5 className="mb-3">Streamlined Workflow</h5>
                        <p>GenAI simplifies the entire AI model development lifecycle, from data preprocessing to model training, evaluation, and deployment, streamlining the process for accelerated solution delivery
                        </p>
                        <a className="btn px-3 mt-auto mx-auto" href="">Read More</a>
                      </div>
                    </div>
                    <div className="col-12 wow fadeIn" data-wow-delay="0.7s">
                      <div className="service-item d-flex flex-column justify-content-center text-center rounded">
                        <div className="service-icon btn-square">
                        <FontAwesomeIcon icon={faCogs } size="3x"  style={{padding:'19px',color:'#00C389'}} />
                        </div>
                        <h5 className="mb-3">Collaboration and Expert Support</h5>
                        <p>Our platform fosters collaboration among teams and provides expert support, promoting knowledge sharing and innovation throughout the AI development journey.</p>
                        <a className="btn px-3 mt-auto mx-auto" href="">Read More</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* ---------------------How We Add Value--------------------------- */}
      <div className="container-fluid bg-light ">
        <div className="container py-5">
          <div className="mx-auto text-center wow fadeIn" data-wow-delay="0.1s" style={{ maxWidth: '500px' }}>
            <h1 className="mb-4">How We Add Value</h1>
          </div>
          <div className="row g-4">
            <div className="col-lg-4 wow fadeIn" data-wow-delay="0.3s">
              <div className="case-item position-relative overflow-hidden rounded mb-2">
                <img className="img-fluid" src="https://demo.htmlcodex.com/2696/artificial-intelligence-html-template/img/project-1.jpg" alt="" />
                <a className="case-overlay text-decoration-none" href="">
                  <small>Business-Centric Approach</small>
                  <h5 className="lh-base text-white mb-3">We align AI solutions with your business objectives, offering bespoke models that drive tangible value and impact</h5>
                  <span className="btn btn-square btn-primary"><FontAwesomeIcon icon={faAngleRight} className="footer-icon me-2" /></span>
                </a>
              </div>
            </div>
            <div className="col-lg-4 wow fadeIn" data-wow-delay="0.5s">
              <div className="case-item position-relative overflow-hidden rounded mb-2">
                <img className="img-fluid" src="https://demo.htmlcodex.com/2696/artificial-intelligence-html-template/img/project-2.jpg" alt="" />
                <a className="case-overlay text-decoration-none " href="">
                  <small >Rapid Deployment</small>
                  <h5 className="lh-base text-white mb-3">Our platform accelerates time-to-market, allowing businesses to swiftly implement AI solutions and capitalize on emerging opportunities</h5>
                  <span className="btn btn-square btn-primary"><FontAwesomeIcon icon={faAngleRight} className="footer-icon me-2" /></span>
                </a>
              </div>
            </div>
            <div className="col-lg-4 wow fadeIn" data-wow-delay="0.7s">
              <div className="case-item position-relative overflow-hidden rounded mb-2">
                <img className="img-fluid" src="https://demo.htmlcodex.com/2696/artificial-intelligence-html-template/img/project-3.jpg" alt="" />
                <a className="case-overlay text-decoration-none" href="">
                  <small>Continuous Improvement</small>
                  <h5 className="lh-base text-white mb-3">With the ability to customize and adapt models, we ensure that AI solutions evolve with your business, staying relevant and effective over time</h5>
                  <span className="btn btn-square btn-primary"><FontAwesomeIcon icon={faAngleRight} className="footer-icon me-2" /></span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default GenAiPlatform