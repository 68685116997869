import React from 'react'
import './opensourceModel.css'
import { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartLine, faEye, faCog, faDollarSign, faUsers, faMoneyBill, faLanguage, faBook } from "@fortawesome/free-solid-svg-icons";
import { faTwitter, faInstagram, faLinkedinIn } from '@fortawesome/free-brands-svg-icons';

function OpensourceModel() {

  useEffect(() => {
    window.scrollTo(0, 0);

  }, []);
  const servicesData = [
    {
      iconClass: 'fa fa-code fa-7x',
      title: 'Web Design',
      description: 'Lorem ipsum dolor sit amet elit. Sed efficitur quis purus ut interdum. Aliquam dolor eget urna ultricies tincidunt.',
    },
    {
      iconClass: 'fa fa-file-code fa-7x',
      title: 'Web Development',
      description: 'Lorem ipsum dolor sit amet elit. Sed efficitur quis purus ut interdum. Aliquam dolor eget urna ultricies tincidunt.',
    },
    {
      iconClass: 'fa fa-external-link-alt fa-7x',
      title: 'UI/UX Design',
      description: 'Lorem ipsum dolor sit amet elit. Sed efficitur quis purus ut interdum. Aliquam dolor eget urna ultricies tincidunt.',
    },
    {
      iconClass: 'fas fa-user-secret fa-7x',
      title: 'Web Security',
      description: 'Lorem ipsum dolor sit amet elit. Sed efficitur quis purus ut interdum. Aliquam dolor eget urna ultricies tincidunt.',
    },
    {
      iconClass: 'fa fa-envelope-open fa-7x',
      title: 'Digital Marketing',
      description: 'Lorem ipsum dolor sit amet elit. Sed efficitur quis purus ut interdum. Aliquam dolor eget urna ultricies tincidunt.',
    },
    {
      iconClass: 'fas fa-laptop fa-7x',
      title: 'Programming',
      description: 'Lorem ipsum dolor sit amet elit. Sed efficitur quis purus ut interdum. Aliquam dolor eget urna ultricies tincidunt.',
    },
  ];

  return (
    <div style={{ marginTop: "150px" }}>
      <div className="container-fluid py-5 my-5">
        <div className="container py-5">
          <div className="row g-5">
            <div className="col-lg-5 col-md-6 col-sm-12 wow fadeIn" data-wow-delay=".3s">
              <div className="h-100 position-relative">
                <img src="https://imageio.forbes.com/specials-images/imageserve/648aaa9fac6d92c810b12f50/Why-Companies-Are-Vastly-Underprepared-For-The-Risks-Posed-By-AI/960x0.jpg?format=jpg&width=1440" className="img-fluid w-75 rounded" alt="" style={{ marginBottom: '25%' }} />
                <div className="position-absolute w-75" style={{ top: '25%', left: '25%' }}>
                  <img src=" https://s40424.pcdn.co/in/wp-content/uploads/2022/10/What-is-M.Tech-in-Artificial-Intelligence_AI.jpg.webp" className="img-fluid w-100 rounded" alt="" />
                </div>
              </div>

            </div>
            <div className="col-lg-7 col-md-6 col-sm-12 wow fadeIn" data-wow-delay=".5s">
              {/* <h5 className="text-primary">About Us</h5> */}
              <h1 className="mb-4">OpenSource Models</h1>
              <p><b>Empowering Innovation with Collaborative AI Solutions</b></p>
              <p className="mb-4">At Enginuo, we embrace the power of collaboration and open-source contributions in AI development, offering Open-Source Models as a cornerstone of our AI solution services.
                These models foster innovation, transparency, and accessibility in the realm of Artificial Intelligence</p>
              <a href="" className="btn rounded-pill px-5 py-3 text-white" style={{ backgroundColor: "#00C389" }}>More Details</a>
            </div>
          </div>
        </div>
      </div>

      {/* ------------------------------------------------ */}

      {/* <div className="container-fluid services ">
      <div className="container py-5">
        <div className="text-center mx-auto pb-5 wow fadeIn" data-wow-delay=".3s" style={{ maxWidth: '600px' }}>
          <h1>Services Built Specifically For Your Business</h1>
        </div>
        <div className="row g-5 services-inner">
          {servicesData.map((service, index) => (
            <div key={index} className="col-md-6 col-lg-4 wow fadeIn" data-wow-delay={`${index % 2 === 0 ? 0.3 : 0.5}s`}>
              <div className="services-item bg-light">
                <div className="p-4 text-center services-content">
                  <div className="services-content-icon">
                    <i className={service.iconClass + ' mb-4 text-primary'}></i>
                    <h4 className="mb-3">{service.title}</h4>
                    <p className="mb-4">{service.description}</p>
                    <a href="" className="btn btn-secondary text-white px-5 py-3 rounded-pill" style={{:"#004aad"}}>Read More</a>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div> */}
      {/* =====================Features and Offerings==================== */}
      <section className="Features section">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 ">
              <div className="section-title text-center  ">
                <h2 className='fw-bold'>Features and Offerings</h2>
              </div>
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-lg-3 col-12">
              {/* Start Single features */}
              <div className="single-features">
                <div className="signle-icon">
                  <FontAwesomeIcon icon={faEye} />
                </div>
                <h3>Transparency and Accessibility</h3>
                <p>Open-Source Models offer transparency in design and implementation, providing insights into model architecture</p>
              </div>
              {/* End Single features */}
            </div>
            <div className="col-lg-3 col-12">
              {/* Start Single features */}
              <div className="single-features">
                <div className="signle-icon">
                  <FontAwesomeIcon icon={faChartLine} />
                </div>
                <h3>Continuous Improvement</h3>
                <p>Being open-source, these models benefit from ongoing community feedback and contributions, leading to continuous enhancements and refinements</p>
              </div>
              {/* End Single features */}
            </div>
            <div className="col-lg-3 col-12">
              {/* Start Single features */}
              <div className="single-features">
                <div className="signle-icon">
                  {/* <i className="text-primary text-primary-stethoscope"></i> */}
                  <FontAwesomeIcon icon={faDollarSign} />
                </div>
                <h3>Cost-Efficiency</h3>
                <p>Leveraging open-source models eliminates licensing costs, making AI development more cost-effective for businesses of all sizes</p>
              </div>
              {/* End Single features */}
            </div>
            <div className="col-lg-3 col-12">
              {/* Start Single features */}
              <div className="single-features last">
                <div className="signle-icon">
                  {/* <i className="text-primary text-primary-stethoscope"></i> */}
                  <FontAwesomeIcon icon={faCog} />
                </div>
                <h3>Versatility and Customization</h3>
                <p>These models serve as a versatile starting point, allowing customization and fine-tuning to suit specific business requirements or use cases</p>
              </div>
              {/* End Single features */}
            </div>
          </div>
        </div>
      </section>


      {/* =========================Industry Applications========================= */}

      <div className="container-fluid py-5 wow fadeInUp industry-application" data-wow-delay="0.1s">
        <div className="container py-5">
          <div className="section-title text-center position-relative pb-3 mb-5 mx-auto" style={{ maxWidth: '600px' }}>
            <h1 className="fw-bold  ">Industry Applications</h1>
            <h5 className="mb-0">Open-Source Models cater to a diverse range of industry applications, such as</h5>
          </div>
          <div className="row g-5">
            <div className="col-lg-4">
              <div className="row g-5">
                <div className="col-12 wow zoomIn" data-wow-delay="0.2s">
                  <div className="rounded d-flex align-items-center justify-content-center  mb-3 industry-icon-card" >
                    <FontAwesomeIcon icon={faLanguage} style={{ color: '#00C389' }} size='3x' />
                  </div>
                  <h4>Natural Language Processing </h4>
                  <p className="mb-0">Sentiment analysis, language translation, text summarization</p>
                </div>
                <div className="col-12 wow zoomIn" data-wow-delay="0.6s">
                  <div className=" rounded d-flex align-items-center justify-content-center mb-3 industry-icon-card" >
                    <FontAwesomeIcon icon={faEye} style={{ color: '00C389' }} size='3x' />
                  </div>
                  <h4>Computer Vision</h4>
                  <p className="mb-0">Object detection, image segmentation, facial recognition</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 wow zoomIn" data-wow-delay="0.9s" style={{ minHeight: '350px' }}>
              <div className="position-relative h-100">
                <img className="position-absolute w-100 h-100 rounded wow zoomIn" data-wow-delay="0.1s" src="https://cdn.dribbble.com/users/1138853/screenshots/4834993/06_08_gif.gif" style={{ objectFit: 'cover' }} alt="Feature" />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="row g-5">
                <div className="col-12 wow zoomIn" data-wow-delay="0.4s">
                  <div className="rounded d-flex align-items-center justify-content-center mb-3 industry-icon-card">
                    {/* <i className="fa fa-users-cog text-white"></i> */}
                    <FontAwesomeIcon icon={faBook} style={{ color: '00C389' }} size='3x' />
                  </div>
                  <h4>Reinforcement Learning</h4>
                  <p className="mb-0">Game playing, robotics, autonomous systems</p>
                </div>
                <div className="col-12 wow zoomIn" data-wow-delay="0.8s">
                  <div className=" rounded d-flex align-items-center justify-content-center mb-3 industry-icon-card">
                    <FontAwesomeIcon icon={faChartLine} style={{ color: '00C389' }} size='3x' />
                  </div>
                  <h4>Predictive Analytics</h4>
                  <p className="mb-0">Time series forecasting, anomaly detection, recommendation systems</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* ===============Business Value================== */}
      <div className="container-xxl py-5">
        <div className="container py-5 px-lg-5">
          <div className="text-center pb-4 wow fadeInUp" data-wow-delay="0.1s">
            <h1 className="mb-5 fw-bold">How We Drive Business Value</h1>
          </div>
          <div className="row gy-5 gx-4 justify-content-center">
            {/* Step 1 */}
            <div className="col-lg-4 col-sm-6 text-center pt-4 wow fadeInUp" data-wow-delay="0.1s">
              <div className="position-relative bg-light rounded pt-5 pb-4 px-4">
                <div className="d-inline-flex align-items-center justify-content-center bg-primary-gradient rounded-circle position-absolute top-0 start-50 translate-middle shadow" style={{ width: '100px', height: '100px' }}>
                  {/* <i className="fa fa-cog fa-3x text-white"></i> */}
                  <FontAwesomeIcon icon={faUsers} style={{ color: '00C389' }} size='3x' />
                </div>
                <h5 className="mt-4 mb-3">Innovation through Collaboration</h5>
                <p className="mb-0">Leveraging Open-Source Models encourages collaboration and knowledge-sharing, fostering innovation and accelerating AI development</p>
              </div>
            </div>
            {/* Step 2 */}
            <div className="col-lg-4 col-sm-6 text-center pt-4 wow fadeInUp " data-wow-delay="0.3s" >
              <div className="position-relative bg-light rounded pt-5 pb-4 px-4" >
                <div className="d-inline-flex align-items-center justify-content-center bg-secondary-gradient rounded-circle position-absolute top-0 start-50 translate-middle shadow" style={{ width: '100px', height: '100px' }}>
                  <FontAwesomeIcon icon={faMoneyBill} style={{ color: '00C389' }} size='3x' />
                </div>
                <h5 className="mt-4 mb-3">Cost-Efficient Solutions</h5>
                <p className="mb-0">By utilizing open-source models, businesses can significantly reduce AI development costs while leveraging high-quality, community-supported models</p>
              </div>
            </div>
            {/* Step 3 */}
            <div className="col-lg-4 col-sm-6 text-center pt-4 wow fadeInUp" data-wow-delay="0.5s">
              <div className="position-relative bg-light rounded pt-5 pb-4 px-4">
                <div className="d-inline-flex align-items-center justify-content-center bg-primary-gradient rounded-circle position-absolute top-0 start-50 translate-middle shadow" style={{ width: '100px', height: '100px' }}>
                  <FontAwesomeIcon icon={faCog} size='3x' style={{ color: '00C389' }} />
                </div>
                <h5 className="mt-4 mb-3">Customizable and Adaptable</h5>
                <p className="mb-0">Open-Source Models serve as a foundation for tailored solutions, allowing for customization to meet specific business needs and objectives</p>
              </div>
            </div>
          </div>
        </div>
      </div>








    </div>
  )
}

export default OpensourceModel